import AdminHead from "../../components/AdminHead.js";
import React, {useEffect} from "react";
import {debounce, isEmpty} from "lodash";
import './RecordedPayments.scss'
import clsx from "clsx";
import RecurTable from "../../../../components/RecurTable/RecurTable.jsx";
import {fetchPresignedUrl, fetchRecordedPayments, markRecordedPayment, markRecordedPaymentBulk} from "../../../../common/ApiHelpers.ts";
import {showNotification} from "../../../../util/utility.js";
import {SHOW_NOTIFICATION, GENERIC_ERROR} from "../../../../constants/enums.js";
// @ts-ignore
import {useHistory} from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserName } from "../../../App/AppReducer.js";
import ReactTooltip from "react-tooltip";
import moment from "moment";

export const getTdsStatusColors = (status: string) => {
    let color = '';
    let banner = '';
    switch (status) {
        case 'OPEN':
            color = '#D6BB0A';
            banner = '#FFFAD7';
            break;
        case 'INV. APPROVED':
        case 'APPROVED':
            color = '#00AA00';
            banner = '#E5FCDE';
            break;
        case 'INV. REJECTED':
        case 'REJECTED':
            color = '#DF666A';
            banner = '#FDF0EC';
            break;
    }
    return {color, banner};
};
const RecordedPayments = () => {
    const [investeeName, setInvesteeName] = React.useState('')
    const [investorName, setInvestorName] = React.useState('')
    const history = useHistory();
    const userName = useSelector(getUserName)
    const BUTTON_ENUMS: any = {
        ALL: "ALL",
        OPEN: 'OPEN',
        INV_APPROVED: 'INV. APPROVED'
    };
    const BUTTON_ENUMS_VALUES: any = {
        ALL: ["OPEN","APPROVED","REJECTED","REJECTED_BY_INVESTOR","APPROVED_BY_INVESTOR"],
        OPEN: ['OPEN'],
        ['INV. APPROVED']: ['APPROVED_BY_INVESTOR']
    };
    const [loading, setLoading] = React.useState(false)
    const [rowData, setRowData] = React.useState([])
    const [tempRowData,setTempRowData] = React.useState<any>([])
    const [buttonSelected, setButtonSelected] = React.useState(BUTTON_ENUMS.OPEN)
    const [selectedPayments, setSelectedPayments] = React.useState<any[]>([]);
    const [selectedPaymentIds, setSelectedPaymentIds] = React.useState<Set<string>>(new Set());
    const [checkedRows, setCheckedRows] = React.useState(new Set());
    const getRowData = async () => {
        setLoading(true);
        const statusList = BUTTON_ENUMS_VALUES[buttonSelected]
        let payload:any = {
            statusList:statusList,
            requestBy: "ADMIN"
        }
        if(investeeName) payload['investeeName'] = investeeName
        if(investorName) payload['investorName'] = investorName
        const res = await fetchRecordedPayments(payload);
        if (res) {
            const convertedRes = res.map((item:any) => {
                item['transactionDate'] = item['transactionDate'] ? moment(item['transactionDate'],'DD MMM YYYY').format('YYYY-MM-DD') : null;
                return item;
            });
            setLoading(false);
            setRowData(convertedRes);
            setTempRowData(convertedRes);
        }
        setLoading(false);
    };

    const verifyRecordedPayment = async (referenceId: string, status: string, amount: Number,transactionDate:string) => {
        if(selectedPaymentIds.has(referenceId)) return;
        let toggle = false;
        if(status === 'APPROVED') toggle = window.confirm(`Want to Approve payment with amount: ${amount} and transactionDate : ${transactionDate}?`);
        else if(status === 'REJECTED') toggle = window.confirm(`Want to Reject payment with amount ${amount}?`);

        if(!toggle) return;

        setLoading(true);

        let payload:any = {
            referenceId: referenceId,
            status: status,
            amount: amount,
            actionBy: userName
        }
        if(transactionDate) payload['transactionDate'] = transactionDate;
        const res = await markRecordedPayment(payload);
        if (res) {
            showNotification(SHOW_NOTIFICATION.SUCCESS, `Successfully ${status} recorded payment`);
            setLoading(false);
        }
        await getRowData();
        setLoading(false);
    };

    const debounced = debounce(() => {
        getRowData();
    }, 1000);
    const handleTextSearch = (value: string, key: string) => {
        // implementing search with debounce of 1 sec to avoid multiple api calls
        if(key === 'investeeName') setInvesteeName(value)
        if(key === 'investorName') setInvestorName(value)
    }

    const downloadDocument = async (docId:string) => {
        const res = await fetchPresignedUrl(docId);
        if (res) {
            window.open(res.signedUrl, '_blank');
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
        }
    }
    const onCheckBoxSelect = (row: any, index: number) => {
        if (selectedPaymentIds.has(row.referenceId)) {
            setSelectedPayments(selectedPayments.filter((item) => item.referenceId !== row.referenceId));
            selectedPaymentIds.delete(row.referenceId)
            checkedRows.delete(index);
        } else {
            const selectedpayment = {
                referenceId: row.referenceId,
                status: "APPROVED",
                amount: row.amount,
                actionBy: userName,
                transactionDate : row.transactionDate
            };
            setSelectedPayments([...selectedPayments, selectedpayment]);
            selectedPaymentIds.add(row.referenceId)
            checkedRows.add(index);
        }
        setSelectedPaymentIds(selectedPaymentIds);
        setCheckedRows(checkedRows);
    }
    const onVerifyMultiplePayments= async() => {
        let toggle = false;
        if(selectedPaymentIds.size === 0){
            showNotification(SHOW_NOTIFICATION.ERROR, `No recorded payment selected`);
            return;
        }
        toggle = window.confirm(`Want to Approve ${selectedPaymentIds.size} Recorded Payments`);

        if(!toggle) return;

        setLoading(true);

        let payload = {
            recordedPayments: selectedPayments
        }
        const res: Map<string, string> = await markRecordedPaymentBulk(payload);
        if (Object.keys(res).length > 0) {
            const mapAsString = Array.from(new Map(Object.entries(res)).entries()).map(([key, value]) => `${key}: ${value}`).join(', ');
            showNotification(SHOW_NOTIFICATION.ERROR, mapAsString);
            setLoading(false);
        } else {
            showNotification(SHOW_NOTIFICATION.SUCCESS, `Successfully Approved ${selectedPaymentIds.size} recorded payments`);
            setLoading(false);
        }
        setSelectedPaymentIds(new Set())
        setCheckedRows(new Set())
        setSelectedPayments([])

        await getRowData();
        setLoading(false);
    }
    const handleChange = (value: any,index:number) => {
        let temp = JSON.parse(JSON.stringify(tempRowData));
        temp[index].amount = value;
        setRowData(temp);
    }
    const handleDateChange = (value: any,index:number) => {
        let temp = JSON.parse(JSON.stringify(tempRowData));
        temp[index].transactionDate = value;
        setRowData(temp);
    }
    useEffect(() => {
        debounced();
        return debounced.cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investeeName]);
    useEffect(() => {
        debounced();
        return debounced.cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investorName]);
    useEffect(() => {
        getRowData();
        setInvestorName('')
        setInvesteeName('')
    }, [buttonSelected]);
    const getRows = () => {
        if (isEmpty(rowData)) {
            return <tbody className={'noDataInTable'}>No recorded payment available</tbody>;
        } else {
            return (
                <tbody>
                {rowData.map((row: any, index: number) => {
                    return (
                        <tr
                            key={index}
                            style={{fontSize: '12px' ,backgroundColor: checkedRows.has(index) ? '#e6faff' : 'white'}}
                        >
                            {/* <td style={{width: '18%' }}>
                                
                                {row.referenceId ?? '-'}</td> */}
                            <td style={{width: '18%'}}>
                            {["OPEN","INV. APPROVED"].includes(row.status) && <input
                                    type='checkbox'
                                    value={index}
                                    style={{ marginLeft: '1rem', marginRight: '0.75rem' }}
                                    checked={selectedPaymentIds.has(row.referenceId)}
                                onChange={(e) => onCheckBoxSelect(tempRowData[index],index)}
                                />}
                                {row.investeeName ?? '-'}
                            </td>
                            <td style={{width: '15%'}}>{row.investorName ?? '-'}</td>
                            <td style={{width: '10%'}}>
                                {row.status === "OPEN" && <input className={'amount-input-box'} type="number" value={row.amount} onChange={(e) => handleChange(e.target.value,index)}></input>}
                                {row.status !== "OPEN" && row.amount}
                            </td>
                            <td style={{width: '10%'}}>
                                {row.status === "OPEN" && <input className={'amount-input-box'} onKeyDown={(e) => e.preventDefault()} type="date" max={moment().format("YYYY-MM-DD")} value={row.transactionDate} onChange={(e) => handleDateChange(e.target.value,index)}></input>}
                                {row.status !== "OPEN" && row.transactionDate}
                            </td>
                            <td style={{maxWidth: '20ch',wordWrap:'break-word',width:'8%',paddingRight:'1rem'}}>{row.utr}</td>
                            <td>{row.actionBy}<br/><span style={{width: '12%', cursor: 'pointer', color: '#2F8FFF'}} onClick={() => downloadDocument(row.docId)}>screenshot </span></td>
                            <td style={{width: '10%'}}>{<span
                                className={'StatusBanner'}
                                style={{
                                    color: getTdsStatusColors(row.status).color,
                                    background: getTdsStatusColors(row.status).banner,
                                }}
                            >
                                        {row.status}
                                        </span>}
                            </td>
                            <td>{row.uploadDate}</td>
                            <td>
                                {row.rejectReason && <span>{row.rejectReason}</span>}
                                {["OPEN","INV. APPROVED"].includes(row.status) && <div className={'button-container'}>
                                    <button className={'approve-button'} onClick={() => verifyRecordedPayment(row.referenceId,"APPROVED",row.amount,row.transactionDate)}>
                                        <img src='../../../../../assets/icon-done.svg' alt=''></img>
                                    </button>
                                    <button className={'reject-button'} onClick={() => verifyRecordedPayment(row.referenceId,"REJECTED",row.amount,moment().format("YYYY-MM-DD"))}>
                                        <img src='../../../../../assets/icon-clear.svg' alt=''></img>
                                    </button>
                                </div> }
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            );
        }
    }
    const getHeaders = () => {
        return [
            {
                jsx: (
                    <span className='header_input'>
                        <input
                            value={investeeName}
                            onChange={(e) => handleTextSearch(e.target.value, "investeeName")}
                            type='text'
                            className='form-control'
                            placeholder='Company Name'
                            style={{border: "none", fontSize: "14px", paddingTop: 0, paddingBottom: 0}}>
                        </input>
                    </span>
                ),
            },
            {
                jsx: (
                    <span className='header_input'>
                        <input
                            value={investorName}
                            onChange={(e) => handleTextSearch(e.target.value, "investorName")}
                            type='text'
                            className='form-control'
                            placeholder='Investor'
                            style={{border: "none", fontSize: "14px", paddingTop: 0, paddingBottom: 0}}>
                        </input>
                    </span>
                ),
            },
            {
                jsx: 'Amount',
            },
            {
                jsx: 'Transaction Date',
            },
            {
                jsx: 'UTR',
            },
            {
                jsx: 'Action by',
            },
            {
                jsx: <span style={{width:'10%',marginLeft:'-0.8rem'}}>Status</span>,
            },
            {
                jsx: 'Upload Date',
            },
            {
                jsx: 'Approve/Reject',
            },
        ];
    }
    return (
        <div className="main-body">
            <AdminHead/>
            <section className='w-100 py-5'>
                <div className='fs21 px-5 pb-3'>Recorded Payments</div>
                <div className="page-head-bottom-border"></div>
                <div className='tdsTableHeadWrapper'>
                    <div className='tdsTableHeadContainer'>
                        <div className='TdsbtnConatiner'>
                            {
                                Object.keys(BUTTON_ENUMS)?.map((key) => {
                                    return (
                                        <div className='ButtonContainer'>
                                            {BUTTON_ENUMS[key] === BUTTON_ENUMS.ALL && (
                                                <label htmlFor="button-label">
                                                    Show request for
                                                </label>
                                            )}
                                            <button
                                                id={
                                                    BUTTON_ENUMS[key] === BUTTON_ENUMS.ALL
                                                        ? 'button-label'
                                                        : ''
                                                }
                                                className={clsx({
                                                    ['Active']: BUTTON_ENUMS[key] === buttonSelected,
                                                    ['Inactive']: BUTTON_ENUMS[key] !== buttonSelected,
                                                })}
                                                onClick={() => {
                                                    setButtonSelected(BUTTON_ENUMS[key]);
                                                }}
                                            >
                                                {BUTTON_ENUMS[key]}
                                            </button>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className='TdsTableLabel' style={{ display: 'flex',  alignItems: 'center' , width: '100%',justifyContent:'space-between'}}>
                            <div>
                            Showing <span
                            style={{fontFamily: 'gilroy-bold,serif'}}>{isEmpty(rowData) ? 0 : 1} - {rowData?.length}</span> of {rowData?.length} entries
                            </div>
                            <button
                                style={{ backgroundColor: '#4C8DFF',padding:'1.2rem 1rem 1.2rem 1rem',display:'flex',alignItems:'center',width:'fit-content' ,marginBottom: "1rem", marginRight: "0rem"}}
                                className='app-btn btn-approve-nopayment'
                                onClick={() => onVerifyMultiplePayments()}>
                                <img src='../../../../../assets/icon-done.svg' alt=''></img>
                                <span style={{whiteSpace:'nowrap'}}>Bulk Approve</span>
                            </button>
                        </div>
                    </div>
                </div>
                <RecurTable
                    changeColorOnHover={false}
                    style={{}}
                    headerStyle={{}}
                    headers={getHeaders()}
                    loading={loading}
                    renderRows={getRows()}
                />

            </section>
        </div>
    )
}

export default RecordedPayments;