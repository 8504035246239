export const RULES = {
    SIGNED_N_STAMPED: "Signed & Stamp",
    DSC_SIGNED: "DSC Signed",
    BORROWING_CLAUSE: "Borrowing Clause",
    SIGNED_FIRST_LAST: "Signed and stamped on first and last page",
}
export const VERIFIED = "Verified";
export const NOT_VERIFIED = "Not Verified";
export const NEED_UPDATE = "Need update";
export const NOT_UPLOADED = "Not uploaded";
export const UPLOADED = "Uploaded";
export const EXPIRED = "Expired";
export const RULE_STATUS = {
    VERIFIED: "V",
    NOT_VERIFIED: "NV"
};
export const ID_CONSTANT = "ID";
export const URL_CONSTANT ="URL";
export const VERIFIED_COUNT = "verifiedCount";
export const TOTAL_COUNT = "totalCount";
export const ORG_TYPE_INVESTEE = "investee";
export const DELAY_CHARGES = "Delay Charges";
export const ORG_TYPE_COMPANY = "Company";
export const ORG_TYPE_INVESTOR = "Investor";
export const CATEGORY_SECTION_MAPPING = {
    "Company KYC": "kyc_docs",
    "Address": "kyc_docs",
    "Company Authorisation": "company_auth",
    "Beneficiary KYC": "beneficiary_add",
    "Addn Docs" : "kyc_docs",
    "Deal Docs" : "deal_docs"
};
export const DOC_KEY_MAPPING = {
    "COI": "certificate_of_incorporation",
    "AOA": "articles_of_association",
    "MOA": "memorandum_of_association",
    "Company PAN": "company_pan",
    "Company KYC SHP": "shareholding_pattern",
    "GST Certificate": "gst_certificate",
    "Utility Bill": "utility_bill",
    "Rent Receipt": "",
    "Rent Agreement": "rent_agreement",
    "BR": "board_resolution",
    "LOD": "list_of_directors",
    "SHP": "share_holding_pattern",
    "Individual PAN AuthSig": "sign_pan",
    "Individual Identity AuthSig": "sign_dl_voter_passport",
    "Institutional GST Indian": "beneficiary_add_gst_certificate",
    "Institutional PAN Indian": "beneficiary_add_sign_pan",
    "Institutional COI Indian": "beneficiary_add_certificate_of_incorporation",
    "Institutional Reg Cert Foreign": "beneficiary_add_registration_certificate",
    "Individual PAN Indian": "beneficiary_add_sign_pan",
    "Individual Passport Indian": "beneficiary_add_sign_dl_voter_passport",
    "Individual PAN Foreign": "beneficiary_add_sign_pan",
    "Individual Passport Foreign": "beneficiary_add_sign_dl_voter_passport",
    "Additional Doc" : "additional_documents",
    'Individual Identity AuthSign Back':'adhaar_back_side',
    "Debt Tot":"debt_tot",
    "Debt Docs":"debt_docs",
    "Spdc Deal Doc":"spdc_deal_doc",
    "Deal Doc":"deal_doc"
};

export const CATEGORY_ID_MAPPING = {
    KYC: 1,
    "SIGNED_KYC" : 2,
    "DEAL_DOCS" : 3
}

export const RECUR_INVESTOR_DEAL_NAME = "RI Deal";
export const DEFAULT_MORAT_VAL_MONTHS = 1;
export const DEAL_DOCS_TYPES = ["Debt Tot", "Debt Docs", "Spdc Deal Doc","Deal Doc"]


//Decentro Verification Statuses
export const DECENTRO_BANK_VERIFIED_SUCCESS = "success";
export const DECENTRO_BANK_PENDING = "pending";
export const DECENTRO_BANK_FAILURE = "failure";
export const DECENTRO_BANK_ATTEMPTED_NOT_VERIFIED = "attemptedNotVerified";
export const DECENTRO_BANK_NOT_VERIFIED = "not verified";

export const MONTHLY = "MONTHLY";
export const QUARTERLY = "QUARTERLY";